import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RegulaminPage = () => (
  <Layout>
    <SEO title="Regulamin i polityka prywatności - Kancelaria Prawna RIO" />
    <section className="top content-page">
      <div className="wrap">
        <h4>Regulamin</h4>
        <h2>oraz polityka prywatności</h2>
      </div>
    </section>
    <section className="regulamin">
      <div className="wrap">
        <p>
          <strong>
            § 1<br />
            Definicje
          </strong>
        </p>
        <p>
          1. Właściciel/Spółka/Kancelaria – spółka działająca pod firmą
          Kancelaria Prawna RIO spółka z ograniczoną odpowiedzialnością, z
          siedzibą w Gdyni, ul. Śląska 54/5, 81-310 Gdynia, wpisana do rejestru
          przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk – Północ w
          Gdańsku, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod
          numerem: 0000578595, NIP: 5862300441
          <br />
          2. Strona – strona internetowa prowadzona pod adresem
          www.kancelariario.pl
          <br />
          3. Użytkownik – osoba odwiedzająca stronę, a także osoba zamierzająca
          skorzystać z usług Kancelarii lecz nie zawarła jeszcze z nią umowy;
          <br />
          4. Klient – osoba korzystająca z usług Kancelarii Prawnej RIO sp. z o.
          o. rozumiana jako osoba fizyczna lub prawna, a także jednostka
          organizacyjna nieposiadająca osobowości prawnej, która zawarła umowę
          świadczenia usług z Kancelarią w zakresie dotyczącym któregokolwiek z
          produktów oferowanych przez Kancelarię;
          <br />
          5. Konsument – Klient będący osobą fizyczną dokonującą z
          przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej
          działalnością gospodarczą lub zawodową.
          <br />
          6. Podmioty Grupy Kapitałowej – osoby prawne i fizyczne, a także
          jednostki organizacyjne nieposiadające osobowości prawnej, związane z
          Kancelarią kapitałowo i współpracujące ze sobą w celu świadczenia na
          rzecz Klientów najwyższej jakości usług obejmujących swoim zakresem
          kompleksową obsługę doradczą w zakresie restrukturyzacji i upadłości.
          Podmiotami Grupy Kapitałowej są:
          <br />
          a. Kancelaria Prawna RIO Oddział Pierwszy Sp. z o. o.
          <br />
          b. Kancelaria Prawna RIO Oddział Drugi Sp. z o. o.
          <br />
          c. BIKBR Sp. z o. o.;
          <br />
          d. „PIKBR” Sp. z o. o.;
          <br />
          e. Ius Memoriae Sp. z o. o.;
          <br />
          7. Zaufani Partnerzy – osoby prawne i fizyczne, a także jednostki
          organizacyjne nieposiadające osobowości prawnej, współpracujące z
          Kancelarią, zobowiązane do przestrzegania regulacji RODO także w
          zakresie powierzanych przez Kancelarię danych osobowych:
          <br />
          a. Anna Bufnal prowadząca działalność gospodarczą pod firmą:
          Kancelaria Radcy Prawnego Anna Bufnal;
          <br />
          b. Google Ireland Limited;
          <br />
          c. Facebook Ireland Limited;
          <br />
          d. Fotolia NL Cooperatief U.A.;
          <br />
          e. Senetic S.A.;
        </p>
        <p>
          <strong>§ 2</strong>
          <br />
          <strong>Adres i przeznaczenie Strony</strong>
        </p>
        <p>
          1. Strona dostępna jest w domenie internetowej o nazwie:
          www.kancelariario.pl
          <br />
          2. Głównym przeznaczeniem Strony jest:
          <br />
          a) sprzedaż usług oferowanych przez Właściciela;
          <br />
          b) prezentacja oferty usługowej Właściciela;
          <br />
          c) umożliwienie pozostawienia przez Użytkowników danych kontaktowych;
          <br />
          d) dokonanie płatności za usługi świadczone przez Właściciela za
          pośrednictwem udostępnionych systemów płatności.
        </p>
        <p>
          <strong>§ 3</strong>
          <br />
          <strong>
            Obowiązek przestrzegania postanowień niniejszego regulaminu
          </strong>
        </p>
        <p>
          1. Użytkownicy odwiedzający stronę obowiązani są do przestrzegania
          postanowień niniejszego regulaminu, a także korzystania ze Strony w
          sposób nie naruszający praw osób trzecich w tym Właściciela.
          <br />
          2. Wszelkie reklamy, ogłoszenia, cennik, informacje generowane przez
          Właściciela stanowią zaproszenie do podjęcia rokowań w celu zawarcia
          umowy, chyba że z ich treści wyraźnie wynika, iż stanowią ofertę
          handlową skierowaną do danej osoby.
        </p>
        <p>
          <strong>§ 4</strong>
          <br />
          <strong>Odpowiedzialność za treści</strong>
        </p>
        <p>
          1. Właściciel dokłada wszelkich starań, by informacje umieszczone na
          Stronie były poprawne oraz aktualizowane na bieżąco, jednakże
          Właściciel nie udziela gwarancji za prawidłowość, aktualność i stałą
          dostępność Strony.
          <br />
          2. Właściciel jest odpowiedzialny jedynie za treści zamieszczone na
          Stronie przez przez niego lub osoby działające na jego zlecenie.
          <br />
          3. Wiążące informacje, zalecenia lub porady Właściciel przekazuje za
          pośrednictwem komunikacji indywidualnej.
          <br />
          4. Właściciel zastrzega sobie prawo do zmiany treści Strony, jej
          uzupełniania, modyfikacji a nawet całkowitego usunięcia zamieszczonych
          informacji.
          <br />
          5. Na Stronie zamieszczone zostaną linki do adresów stron
          internetowych innych podmiotów. Właściciel nie odpowiada za treści
          zamieszczone pod tymi adresami.
        </p>
        <p>
          <strong>§ 5</strong>
          <br />
          <strong>Ochrona praw autorskich</strong>
        </p>
        <p>
          1. Zawartość Strony (treść, kształt, struktura) chroniona jest polskim
          i międzynarodowym prawem autorskim, w szczególności poprzez
          postanowienia ustawy z dnia 04 lutego 1994 r. o prawie autorskim i
          prawach pokrewnych (Dz. U. 2006 r., Nr 90, poz. 631 z późn. zm.).
          <br />
          2. Prawa do wszelkich materiałów zamieszczanych na Stronie zastrzeżone
          są na rzecz Właściciela lub podmiotów uprawnionych (np. klientów,
          partnerów handlowych, itp.).
          <br />
          3. Żadna część Strony nie może być kopiowana lub rozpowszechniana w
          jakiejkolwiek formie lub w jakikolwiek sposób, w tym poprzez
          fotokopiowanie, drukowanie, CD/DVD bądź inne środki służące
          przechowywaniu danych, bez zgody Właściciela. Ewentualne odstępstwa od
          powyższej zasady przewidziano w regulaminie.
          <br />
          4. Pobieranie i kopiowanie treści zamieszczonych na stronie dozwolone
          jest wyłącznie do prywatnego, niekomercyjnego użytku.
          <br />
          5. Naruszenie licencji i praw autorskich przez Użytkownika
          sankcjonowane jest zgodnie z przepisami powszechnie obowiązującego
          prawa, a zwłaszcza zgodnie z ustawą o prawie autorskim i prawach
          pokrewnych, ustawą o zwalczaniu nieuczciwej konkurencji, ustawą Kodeks
          cywilny.
        </p>
        <p>
          <strong>§ 6</strong>
          <br />
          <strong>Ochrona danych osobowych</strong>
        </p>
        <p>
          1. Kancelaria przetwarza dane osobowe jej Klientów oraz Użytkowników
          będących osobami fizycznymi lub osób fizycznych będących częścią
          struktury Klientów oraz Użytkowników będących osobami prawnymi lub
          jednostkami organizacyjnymi posiadającymi osobowość prawną zgodnie z
          regulacjami Rozporządzenia Parlamentu Europejskiego i Rady (UE) z dnia
          27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o ochronie danych) – zwane dalej: RODO.
          <br />
          2. Przekazanie przez Użytkowników lub Klientów danych osobowych
          stanowi wymóg umowny umożliwiający Administratorowi świadczenie usług
          na rzecz Klienta lub Użytkownika. Niepodanie danych skutkować może
          brakiem możliwości świadczenia usług na rzecz Klienta lub Użytkownika.
          <br />
          3. Korzystając z usług Kancelarii zarówno za pośrednictwem Strony jak
          i poprzez inne formy kontaktu, także na wstępnym etapie ustalania
          warunków współpracy, Użytkownicy lub Klienci powierzają Kancelarii
          swoje dane osobowe.
          <br />
          4. Jakakolwiek forma przetwarzania danych osobowych Klientów lub
          Użytkowników wymaga uprzedniego uzyskania ich wyraźnej i dobrowolnej
          zgody.
          <br />
          5. Kancelaria uprawniona będzie także do przetwarzania danych
          osobowych Klientów lub Użytkowników w zakresie niezbędnym do wykonania
          umowy, której stroną jest Klient lub do podjęcia działań na żądanie
          Użytkownika przed zawarciem umowy.
          <br />
          6. Administratorem danych osobowych przetwarzanych zgodnie z
          niniejszym regulaminem, ze szczególnym uwzględnieniem charakteru,
          zakresu, kontekstu i celów przetwarzania, a także ryzyk naruszenia
          praw lub wolności Klientów lub Użytkowników jest: Kancelaria Prawna
          RIO Spółka z ograniczoną odpowiedzialnością. Celem skontaktowania się
          w sprawach dotyczących ochrony danych osobowych udostępniono
          następujące kanały komunikacji:
          <br />
          a. Kancelaria Prawna RIO Sp. z o. o., ul. Śląska 54/5, 81-310 Gdynia
          oraz
          <br />
          b. rodo@kancelariario.pl.
          <br />
          7. Dane osobowe Klientów lub Użytkowników mogą być przetwarzane w
          następujących celach:
          <br />
          a. świadczenie usług w oparciu o zawarte umowy pomiędzy Kancelarią a
          Klientem,
          <br />
          b. przygotowanie do zawarcia umowy z Użytkownikiem,
          <br />
          c. marketing, w tym profilowanie i cele analityczne,
          <br />
          d. świadczenie usług drogą elektroniczną,
          <br />
          e. dopasowanie treści stron internetowych do preferencji i
          zainteresowań,
          <br />
          f. wykrywanie botów i nadużyć w usługach,
          <br />
          g. pomiary statystyczne i udoskonalenie usług (cele analityczne).
          <br />
          8. Po uzyskaniu zgody, Kancelaria może powierzyć przetwarzanie danych
          osobowych Użytkowników lub Klientów Podmiotom Grupy Kapitałowej oraz
          Zaufanym Partnerom, jednakże wyłącznie w celach opisanych w § 6
          niniejszego regulaminu. Ponadto dane osobowe mogą zostać przekazane
          podmiotom przetwarzającym je na zlecenie administratora, w oparciu o
          zawarte umowy powierzenia, takim jak: dostawcy usług IT, agencje
          marketingowe, firmy badawcze.
          <br />
          9. Jakiekolwiek dane osobowe nie będą przekazywane państwom trzecim
          będącym poza Europejskim Obszarem Gospodarczym.
          <br />
          10. Dane osobowe Użytkowników lub Klientów przetwarzane w oparciu o
          wyrażoną zgodę przechowywane będą przez Administratora w formie
          umożliwiającej identyfikację osoby, której dane dotyczą, przez okres
          nie dłuższy, niż jest to niezbędne do celów, w których dane te są
          przetwarzane, z zastrzeżeniem istniejącego po stronie Klientów lub
          Użytkowników uprawnień w zakresie prawa do odwołania zgody na
          przetwarzanie danych osobowych. Po skutecznym odwołaniu zgody w myśl
          zdania poprzedniego, Kancelaria przetwarzać będzie dane osobowe
          wyłącznie przez okres odpowiadający okresowi przedawnienia roszczeń,
          jakie może podnosić Administrator oraz jakie mogą być kierowane w
          stosunku do Administratora.
          <br />
          11. Dane osobowe Użytkowników lub Klientów przetwarzane na podstawie
          umowy zawartej z Administratorem przechowywane będą w formie
          umożliwiającej identyfikację osoby, której dane dotyczą, przez okres
          nie dłuższy, niż jest to niezbędne do wykonania przedmiotu umowy. Po
          wskazanym okresie Kancelaria przetwarzać będzie dane osobowe wyłącznie
          przez okres odpowiadający okresowi przedawnienia roszczeń, jakie może
          podnosić Administrator oraz jakie mogą być kierowane w stosunku do
          Administratora.
          <br />
          12. Okresy przetwarzania danych osobowych wymienione w § 6 ust. 8 i 9
          niniejszego regulaminu mogą ulec modyfikacji w zakresie w jakim
          wymagać tego będą przepisy szczególne, np.: ustawa o rachunkowości.
          <br />
          13. Dane osobowe Użytkowników lub Klientów przetwarzane będą w oparciu
          o następujące podstawy prawne:
          <br />
          a. w celach marketingowych w tym profilowania i analityki – na
          podstawie wyraźnej zgody udzielonej w myśl przepisów art. 6 ust. 1
          lit. „a” RODO;
          <br />
          b. w celach świadczenia usług w oparciu o zawarte umowy, przygotowania
          do zawarcia umowy z Użytkownikiem oraz świadczenia usług drogą
          elektroniczną – na podstawie art. 6 ust. 1 lit. „b” RODO jako
          przetwarzanie niezbędne do wykonania umowy lub do podjęcia działań na
          żądanie Użytkownika lub Klienta, przed zawarciem umowy;
          <br />
          c. celem dopasowania treści stron internetowych do preferencji i
          zainteresowań Użytkowników lub Klientów, wykrywania botów i nadużyć w
          usługach, a także dokonywania pomiarów statystycznych i udoskonalenia
          usług – w trybie przepisów art. 6 ust. 1 lit. „f” RODO.
          <br />
          14. W związku z przetwarzaniem danych osobowych Użytkownikom i
          Klientom przysługują następujące prawa:
          <br />
          a. do żądania od Administratora dostępu do danych osobowych, ich
          sprostowania, usunięcia lub ograniczenia przetwarzania,
          <br />
          b. do wniesienia sprzeciwu wobec przetwarzania,
          <br />
          c. do wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony
          Danych Osobowych),
          <br />
          d. do przenoszenia danych,
          <br />
          e. do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z
          prawem przetwarzania, którego dokonano na podstawie zgody przed jej
          cofnięciem.
          <br />
          15. Mając na uwadze zapewnienie indywidualnie dostosowanej oferty, a
          także w toku procedury zmierzającej do zawarcia lub wykonania umowy
          oraz w przypadku uzyskania wyraźnej zgody Użytkownika lub Klienta
          Kancelaria może stosować profilowanie rozumiane jako przetwarzanie
          danych osobowych polegające na ich wykorzystaniu do analiz lub prognoz
          dotyczących indywidulanych zainteresowań i preferencji Użytkownika
          klub Klienta.
          <br />
          16. Kancelaria nie podejmuje decyzji wyłącznie w oparciu o
          zautomatyzowane przetwarzanie danych osobowych Użytkownika lub
          Klienta.
        </p>
        <p>
          <strong>§ 7</strong>
          <br />
          <strong>Polityka plików „cookies”</strong>
        </p>
        <p>
          1. Administrator informuje, że na Stronie korzysta on z tzw. plików
          „cookies” – niewielkich plików tekstowych zapisywanych przez
          przeglądarkę internetową oraz innych technologii do rejestrowania
          informacji o stronach odwiedzanych przez Użytkownika lub Klienta oraz
          o ich aktywności. Pliki „cookies” używane są w celu dostosowania
          zawartości stron internetowych do preferencji użytkownika oraz
          optymalizacji korzystania ze stron internetowych. Używane są również w
          celu tworzenia anonimowych statystyk, które pomagają zrozumieć w jaki
          sposób użytkownik korzysta ze stron internetowych co umożliwia
          ulepszanie ich struktury i zawartości, z wyłączeniem personalnej
          identyfikacji użytkownika.
          <br />
          2. Pliki te nie gromadzą danych osobowych Użytkownika lub Klienta, nie
          zmieniają konfiguracji jego komputera, nie służą do instalowania bądź
          deinstalacji jakichkolwiek programów komputerowych, wirusów lub
          trojanów, nie ingerują w integralność systemu bądź danych Użytkownika
          lub Klienta, nie są przetwarzanie przez inne serwisy internetowe i
          mogą być w każdym momencie usunięte przez Użytkownika lub Klienta.
          <br />
          3. Pliki „cookies” mogą zostać zamieszczane przez oraz przekazywane do
          Podmiotów Grupy Kapitałowej oraz Zaufanych Partnerów, którzy
          zobowiązani są do przestrzegania wszelkich regulacji wynikających z
          RODO.
          <br />
          4. Dane osobowe gromadzone przy użyciu plików „cookies” mogą być
          zbierane wyłącznie w celu wykonywania określonych funkcji na rzecz
          Użytkownika lub Klienta. Takie dane są zaszyfrowane w sposób
          uniemożliwiający dostęp do nich osobom nieuprawnionym.
          <br />
          5. Standardowo oprogramowanie służące do przeglądania stron
          internetowych domyślnie dopuszcza umieszczanie plików „cookies” na
          urządzeniu końcowym. Ustawienia te mogą zostać zmienione w taki
          sposób, aby blokować automatyczną obsługę plików „cookies” w
          ustawieniach przeglądarki internetowej bądź informować o ich
          każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe
          informacje o możliwości i sposobach obsługi plików „cookies” dostępne
          są w ustawieniach oprogramowania (przeglądarki internetowej) –
          dodatkowe informacje dostępne są na odpowiednich stronach przeglądarek
          Internet Explorer, Mozilla Firefox, Google Chrome, Opera.
          <br />
          6. Ograniczenie stosowania plików „cookies”, może wpłynąć na niektóre
          funkcjonalności dostępne na Stronie.
          <br />
          7. Administrator korzysta z następujących rodzajów plików „cookies” i
          innych tego typu technologii, rozróżnianych z uwagi na:
          <br />
          a. czas ich działania:
          <br />
          • sesyjne – pliki przechowywane w urządzeniu końcowym Użytkownika lub
          Klienta do czasu wylogowania, opuszczenia strony internetowej i
          aplikacji lub wyłączenia oprogramowania;
          <br />
          • stałe – przechowywane w urządzeniu końcowym przez czas określony w
          parametrach plików cookies lub do czasu ich usunięcia przez
          Użytkownika lub Klienta;
          <br />
          b. cel jakiemu służą:
          <br />
          • zapewnienie bezpieczeństwa – wsparcie rozwiązań technicznych
          przeciwdziałających nadużyciom w serwisach internetowych, w tym na
          Stronie,
          <br />
          • usprawnienie funkcjonalności Strony – polegające na „zapamiętywaniu”
          preferencji Użytkownika lub Klienta i służące personalizacji
          interfejsu;
          <br />
          • określenie wydajności – pozwalają na zbieranie informacji o sposobie
          korzystania ze Strony;
          <br />
          • tworzenie statystyk;
          <br />
          • dostarczanie treści reklamowych dostosowanych do preferencji
          Użytkownika lub Klienta;
          <br />
          • zapewnienie działania usług i aplikacji – np. pliki
          uwierzytelniające niezbędne do świadczenia usług wymagających
          uwierzytelnienia.
          <br />
          8. Inne technologie wykorzystywane przez Administratora dotyczą m. in.
          sygnałów nawigacyjnych w sieci web, pikseli oraz anonimowych tagów
          sieci reklamowych. Wśród rozwiązań technicznych stosowanych przez
          Administratora znajdują się przede wszystkim:
          <br />
          a. analityka stron www – Google Analytics,
          <br />
          b. tagi remarketingowe – Google AdWords,
          <br />
          c. usługi Pixel Facebook.
          <br />
          9. Powyższe rozwiązania techniczne udostępnianie są przez Zaufanych
          Partnerów Administratora, z poszanowaniem uprawnień Użytkowników i
          Klientów wynikających z RODO. Dodatkowe informacje dotyczące polityki
          prywatności znajdują udostępniane są bezpośrednio przez Zaufanych
          Partnerów.
        </p>
        <p>
          <strong>§ 8</strong>
          <br />
          <strong>Wtyczki społecznościowe</strong>
        </p>
        <p>
          Celem prowadzenia Strony w sposób bardziej atrakcyjny i umożliwiający
          większą indywidualizację, Administrator umieszcza na Stronie tzw.
          „wtyczki społecznościowe” pochodzące z następujących serwisów:
          <br />
          a. Facebook – Strona zawiera pola „Lubię to” lub „Podziel się”
          powiązane z serwisem Facebook (www.facebook.com), po skorzystaniu z
          któregokolwiek z w/w pól Użytkownik lub Klient zostanie przekierowany
          na stronę logowania serwisu Facebook albo stronę główną konta
          Użytkownika lub Klienta tego serwisu (jeżeli pozostaje zalogowany w
          serwisie Facebook). Polityka prywatności Facebook udostępniana jest
          indywidualnie przez ten serwis;
          <br />
          b. YouTube – Strona może zawierać filmy wideo udostępniane z serwisu
          YouTube (www.youtube.com). W związku z tym na Stronie umieszczany jest
          kod odwołujący się do serwisu YouTube. Polityka prywatności serwisu
          YouTube udostępniana jest indywidualnie przez ten serwis;
          <br />
          c. Twitter – Strona zawiera pola dodawania wpisów, które są powiązane
          z serwisem Twitter (www.twitter.com). Korzystając z tego pola i dodają
          wpis Użytkownik lub Klient zostanie przekierowany na stronę logowania
          serwisu Twitter albo stronę główną konta Użytkownika lub Klienta tego
          serwisu (jeżeli pozostaje zalogowany w serwisie Twitter). Polityka
          prywatności Twitter udostępniana jest indywidualnie przez ten serwis;
          <br />
          d. Google + – Strona zawiera pole „Udostępnij” powiązane z serwisem
          Google+ (www.plus.google.com), po skorzystaniu z którego Użytkownik
          lub Klient zostanie przekierowany na stronę logowania serwisu Google +
          albo stronę główną konta Użytkownika lub Klienta tego serwisu (jeżeli
          pozostaje zalogowany w serwisie Google +). Polityka prywatności Google
          + udostępniana jest indywidualnie przez ten serwis.
        </p>
        <p>
          <strong>§ 9</strong>
          <br />
          <strong>Warunki świadczenia usług</strong>
        </p>
        <p>
          1. Kancelaria świadczy usługi w zakresie udzielania pomocy prawnej
          dotyczącej prowadzenia postępowań upadłościowych i
          restrukturyzacyjnych osób fizycznych oraz prawnych, m. in. poprzez:
          <br />
          a) udzielanie porad prawnych przez wykwalifikowanych prawników
          posiadających bogate doświadczenie zawodowe, podczas spotkań
          organizowanych w siedzibie Kancelarii;
          <br />
          b) udzielanie porad/sporządzanie opinii prawnych przesyłanych za
          pośrednictwem poczty elektronicznej (e-mail);
          <br />
          c) konsultacje prawne udzielane podczas rozmów telefonicznych
          prowadzonych z Klientami;
          <br />
          d) sporządzanie wniosków o ogłoszenie upadłości lub wszczęcie
          postępowań restrukturyzacyjnych;
          <br />
          e) reprezentację Klientów w toku postępowań upadłościowych lub
          restrukturyzacyjnych.
          <br />
          2. Świadczenie usług na rzecz Klientów Kancelarii następuje wyłącznie
          po uprzednim zawarciu pisemnej umowy pomiędzy Klientem a Kancelarią
          oraz wypełnieniu przez Klienta obowiązków umownych m. in. w zakresie
          dostarczenia niezbędnych informacji i dokumentów oraz uiszczenia
          wynagrodzenia należnego Kancelarii.
        </p>
        <p>
          <strong>§ 10</strong>
          <br />
          <strong>Cena</strong>
        </p>
        <p>
          1. Cena świadczenia poszczególnych usług przez Właściciela ustalana
          jest indywidualnie na etapie sporządzania umowy zawieranej pomiędzy
          Właścicielem a Klientem i uzależniona jest od specyfiki każdej ze
          spraw Klienta.
          <br />
          2. Kancelaria zastrzega możliwość oferowania różnych cen świadczonych
          usług względem każdego z Klientów. Właściciel przewiduje możliwość
          stosowania okresowych promocji.
          <br />
          3. Usługa nie obejmuje kosztów dostępu do sieci internetowej lub
          telefonicznej, które są skalkulowane przez dostawców mediów i nie są
          zależne od Właściciela.
          <br />
          4. Usługa może zostać opłacona:
          <br />
          a) za pomocą karty płatniczej,
          <br />
          b) za pomocą przelewu bankowego,
          <br />
          c) za pomocą przekazu pocztowego,
          <br />
          d) gotówką w siedzibie Właściciela.
          <br />
          4. Właściciel, działając za pośrednictwem wykwalifikowanych
          pracowników przed zawarciem każdej z umów, przygotowywać będzie
          precyzyjną i kompleksową wycenę usług. Sporządzona i przesłana wycena
          obowiązuje przez okres 30 (słownie: trzydziestu) dni od dnia jej
          stworzenia.
        </p>
        <p>
          <strong>§ 11</strong>
          <br />
          <strong>Koszty dostawy</strong>
        </p>
        <p>
          1. Dostawa jest dokonywana za pośrednictwem operatora pocztowego
          wybranego przez Właściciela. Koszt dostawy ponosi Klient w oparciu o
          aktualne cenniki operatora dokonującego dostawy, obowiązujące w dniu
          nadania przesyłki.
          <br />
          2. Na wyraźne życzenie, a także ryzyko Klienta dostawa może zostać
          dokonana przez innego wybranego przez Klienta operatora pocztowego,
          koszt dodatkowy pokrywa Klient.
        </p>
        <p>
          <strong>§ 12</strong>
          <br />
          <strong>Czas realizacji zamówienia</strong>
        </p>
        <p>
          Czas realizacji usług objętych zawartymi umowami uzależniony jest od
          wielu czynników, m.in. od:
          <br />
          a) stopnia skomplikowania sprawy,
          <br />
          b) tempa rozpatrywania spraw przez sądy lub organy administracji,
          <br />
          c) ilości pracy niezbędnej do świadczenia danej usługi,
          <br />
          d) sprawności współpracy prowadzonej pomiędzy Kancelarią a Klientem.
        </p>
        <p>
          <strong>§ 13</strong>
          <br />
          <strong>Postępowanie reklamacyjne</strong>
        </p>
        <p>
          1. Klientowi przysługuje uprawnienie do zgłoszenia reklamacji w
          zakresie realizacji usług przez Kancelarię poprzez wypełnienie
          formularza reklamacyjnego zamieszczonego na Stronie albo poprzez
          skierowanie pisemnej reklamacji na adres siedziby Kancelarii.
          <br />
          2. Wszelkie zgłoszenia reklamacyjne powinny zawierać co najmniej:
          <br />
          a) dane pozwalające zidentyfikować Klienta (np. firmę, NIP, imię i
          nazwisko, osobę uprawnioną do uzyskania odszkodowania, numer rachunku
          bankowego oraz nazwę Banku),
          <br />
          b) dane kontaktowe (np. nr telefonu, adres e-mail, adres
          korespondencyjny),
          <br />
          c) nr transakcji/zamówienia, co do którego zgłoszono reklamację,
          <br />
          d) zwięzły opis zastrzeżeń co do świadczonych usług.
          <br />
          3. Termin rozpatrywania reklamacji wynosi 30 (słownie: trzydzieści)
          dni od dnia doręczenia do Kancelarii prawidłowo sporządzonej
          reklamacji zgodnie z zapisami § 13 Regulaminu. Powyższy termin może
          ulec wydłużeniu w sytuacji, gdy specyfika złożonej reklamacji wymaga
          przeprowadzenia postępowania wyjaśniającego. Stosowna informacja
          dotycząca modyfikacji terminu rozpatrzenia reklamacji zostanie
          przekazana Klientowi.
          <br />
          4. Kancelaria może żądać od Klienta przedłożenia dodatkowych
          informacji oraz dokumentów poza wymienionymi w § 13 ust. 2 Regulaminu.
          <br />
          5. Wniesienie reklamacji w trybie § 13 Regulaminu nie zwalnia Klienta
          z obowiązku zapłaty wynagrodzenia przysługującego Kancelarii z tytułu
          świadczenia usług.
        </p>
        <p>
          <strong>§ 14</strong>
          <br />
          <strong>Możliwości odstąpienia Umowy przez Klienta</strong>
        </p>
        <p>
          1. Klientowi będącemu konsumentem w rozumieniu przepisów art. 221
          ustawy z dnia 23 kwietnia 1964 roku Kodeks cywilny (Dz. U. 1964 Nr 16,
          poz. 93 ze zm.) stosownie za przepisów ustawy z dnia 30 maja 2014 roku
          o prawach konsumenta (Dz. U. z 2014 r., poz. 827 ze zm.) przysługuje
          uprawnienie do odstąpienia od zawartej umowy w terminie 14 (słownie:
          czternaście) dni od dnia jej zawarcia.
          <br />
          2. Zlecając rozpoczęcie wykonania usługi przed terminem 14 dni, o
          którym mowa w § 1 4 ust. 1 Regulaminu Klient traci uprawnienie do
          dostąpienia od Umowy.
        </p>
        <p>
          <strong>§ 15</strong>
          <br />
          <strong>Wskazówki techniczne</strong>
        </p>
        <p>
          1. Wykorzystywanie różnego rodzaju przeglądarek internetowych może
          doprowadzić do powstawania różnic w sposobie prezentowania treści
          zamieszczonych na Stronie.
          <br />
          2. Cyfrowe przetwarzanie danych wiąże się z prawdopodobieństwem
          powstawania błędów.
        </p>
      </div>
    </section>
  </Layout>
)

export default RegulaminPage
